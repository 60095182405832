export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'host',
    label: '',
    Object: 'value'
  }, {
    prop: 'image',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'unit_no',
    label: '',
    Object: 'value'
  }, {
    prop: 'detail_address',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'postal_code',
    label: '',
    Object: 'value'
  }, {
    prop: 'remarks',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'address_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'city',
    label: '',
    Object: 'value'
  }, {
    prop: 'province',
    label: '',
    Object: 'value'
  }, {
    prop: 'region',
    label: '',
    Object: 'value'
  }, {
    prop: 'lat',
    label: '',
    Object: 'value'
  }, {
    prop: 'lon',
    label: '',
    Object: 'value'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'deleted_at',
    label: '',
    Object: 'value'
  }]
}
